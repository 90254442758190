import { TrafficSource } from '@citruscamps/citrus-client'
import { CookieSetOptions } from 'universal-cookie'
import { useCookiePreferences } from './useCookiePreferences'

export interface TrafficSourceCookieSetOptions extends CookieSetOptions {
  disableUndefined?: boolean
}

export interface FetchTrafficSource {
  data?: TrafficSource
  handleRemoveSource: () => void
}

export const useFetchTrafficSource = (): FetchTrafficSource => {
  const [data, setData] = useCookiePreferences<TrafficSource>('traffic-source')
  const handleRemoveSource = () => {
    setData(undefined)
  }
  return { data, handleRemoveSource }
}
