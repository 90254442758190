import React from 'react'
import { ControlProps, CSSObjectWithLabel, OptionProps, SingleValueProps } from 'react-select'
import { ExplorePreferences } from '@citruscamps/citrus-client'
import { THEME_COLOR } from '../constants/website'

export const DefaultBrandAccentColor = '#6c757d'
export const DefaultErrorColor = '#E63757'
export const DefaultDark = '#343A40'
export const DefaultLight = '#F8F9FA'
export const DefaultCitrusBrandAccentColor = '#F5841F'

export const addAlpha = (color: string, opacity: number): string => {
  // coerce values so ti is between 0 and 1.
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255)
  return color + _opacity.toString(16).toUpperCase()
}

export const calculateRGB = (color: string): { R: number; G: number; B: number } => {
  color = color.includes('#') ? color : `#${color}`
  let R = parseInt(color.substring(1, 3), 16)
  let G = parseInt(color.substring(3, 5), 16)
  let B = parseInt(color.substring(5, 7), 16)
  R = parseInt(`${(R * 100) / 100}`)
  G = parseInt(`${(G * 100) / 100}`)
  B = parseInt(`${(B * 100) / 100}`)
  return { R, G, B }
}

export const flattenedColor = (fgColor: string, bgColor: string, opacity: number = 1): string => {
  const fgCol = calculateRGB(fgColor)
  const bgCol = calculateRGB(bgColor)

  let { R, G, B } = {
    R: opacity * fgCol.R + (1 - opacity) * bgCol.R,
    G: opacity * fgCol.G + (1 - opacity) * bgCol.G,
    B: opacity * fgCol.B + (1 - opacity) * bgCol.B,
  }
  R = R < 255 ? R : 255
  G = G < 255 ? G : 255
  B = B < 255 ? B : 255

  var RR = R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16)
  var GG = G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16)
  var BB = B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16)

  return '#' + RR + GG + BB
}

export const lighten = (color: string, percent: number): string => {
  let { R, G, B } = calculateRGB(color)

  R = parseInt(`${(R * (100 + percent)) / 100}`)
  G = parseInt(`${(G * (100 + percent)) / 100}`)
  B = parseInt(`${(B * (100 + percent)) / 100}`)

  R = R < 255 ? R : 255
  G = G < 255 ? G : 255
  B = B < 255 ? B : 255

  var RR = R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16)
  var GG = G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16)
  var BB = B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16)

  return '#' + RR + GG + BB
}

export const darken = (color: string, percent: number): string => {
  percent *= -1
  return lighten(color, percent)
}

// https://codepen.io/andreaswik/pen/YjJqpK
export const lightOrDark = (color: string): 'light' | 'dark' => {
  let r: number = 0
  let g: number = 0
  let b: number = 0
  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    let colorSplit: RegExpMatchArray | null = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/,
    )
    if (colorSplit) {
      r = +colorSplit[1]
      g = +colorSplit[2]
      b = +colorSplit[3]
    }
  } else {
    // If HEX --> Convert it to RGB: http://gist.github.com/983661
    let colorNum: number = +('0x' + color.slice(1).replace(color.length > 4 ? '' : /./g, '$&$&'))

    r = colorNum >> 16
    g = (colorNum >> 8) & 255
    b = colorNum & 255
  }

  // HSP equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

  const isBrandColor = color === `#${THEME_COLOR}` || color === DefaultBrandAccentColor

  // Using the HSP value, determine whether the color is light or dark
  if (hsp <= 127.5 || isBrandColor) {
    return 'dark'
  } else {
    return 'light'
  }
}

const luminanace = (color: string) => {
  const { R, G, B } = calculateRGB(color)
  var a = [R, G, B].map((v) => {
    v /= 255
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4)
  })
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722
}

export const contrast = (frontColor: string, backColor: string): number => {
  const luminanceFront = luminanace(frontColor)
  const luminanceBack = luminanace(backColor)
  return luminanceBack > luminanceFront
    ? (luminanceFront + 0.05) / (luminanceBack + 0.05)
    : (luminanceBack + 0.05) / (luminanceFront + 0.05)
}

export const getProgramCardImageStyle = (preferences?: ExplorePreferences): React.CSSProperties => {
  const brand_accent_color: string = preferences?.brand_accent_color
    ? `#${preferences?.brand_accent_color}`
    : DefaultBrandAccentColor
  return {
    backgroundColor: addAlpha(brand_accent_color, 0.25),
  }
}

export const getEventImageStyles = (preferences?: ExplorePreferences): React.CSSProperties => {
  const brand_accent_color: string = preferences?.brand_accent_color
    ? `#${preferences?.brand_accent_color}`
    : DefaultBrandAccentColor
  return {
    backgroundColor: addAlpha(brand_accent_color, 0.25),
  }
}

export const getSelectStyles = (
  preferences?: ExplorePreferences,
  options?: { error: boolean },
): Partial<any> => {
  const brand_accent_color: string = getSafeBrandColor(preferences)
  return {
    control: (base: CSSObjectWithLabel, props: ControlProps<any, any>) => ({
      ...base,
      borderBottomWidth: 1,
      borderColor: options?.error ? DefaultErrorColor : brand_accent_color,
      '&:hover': {
        borderColor: addAlpha(brand_accent_color, 0.5),
      },
      borderRadius: '3.125rem',
      boxShadow: 'none',
    }),
    option: (base: CSSObjectWithLabel, props: OptionProps<any, any>) => ({
      ...base,
      backgroundColor: props.isSelected ? brand_accent_color : 'transparent',
      '&:hover': {
        backgroundColor: addAlpha(brand_accent_color, 0.25),
      },
      color: props.isSelected ? DefaultLight : props.isFocused ? brand_accent_color : DefaultDark,
    }),
    indicatorSeparator: (base: CSSObjectWithLabel) => ({
      ...base,
      display: 'none',
    }),
    dropdownIndicator: (base: CSSObjectWithLabel) => ({
      ...base,
      color: options?.error ? DefaultErrorColor : `#${brand_accent_color}`,
    }),
    singleValue: (base: CSSObjectWithLabel, props: SingleValueProps<any>) => {
      const opacity = props.isDisabled ? 0.5 : 1
      const transition = 'opacity 300ms'

      return { ...base, opacity, transition }
    },
    valueContainer: (base: CSSObjectWithLabel) => ({
      ...base,
      display: 'flex',
    }),
    multiValue: (base: CSSObjectWithLabel) => ({
      ...base,
      borderRadius: '3.125rem',
    }),
    multiValueRemove: (base: CSSObjectWithLabel) => ({
      ...base,
      '&:hover': {
        backgroundColor: 'transparent',
      },
      borderRadius: '3.125rem',
    }),
  }
}

export const getHeaderBackground = (
  preferences?: Partial<ExplorePreferences>,
): React.CSSProperties => {
  return {
    backgroundColor: preferences?.brand_accent_color
      ? `#${preferences?.brand_accent_color.split('#').join('')}`
      : DefaultBrandAccentColor,
  }
}

export const getSafeBrandColor = (preferences?: Partial<ExplorePreferences>): string => {
  const brand_accent_color: string = preferences?.brand_accent_color
    ? `#${preferences?.brand_accent_color.split('#').join('')}`
    : DefaultBrandAccentColor
  return lightOrDark(brand_accent_color) === 'light'
    ? darken(brand_accent_color, 50)
    : brand_accent_color
}

export const getBrandColor = (preferences?: Partial<ExplorePreferences>): string => {
  return preferences?.brand_accent_color
    ? `#${preferences?.brand_accent_color.split('#').join('')}`
    : DefaultBrandAccentColor
}

export const getComplementaryColor = (preferences?: Partial<ExplorePreferences>): string => {
  const brand_accent_color: string = getBrandColor(preferences)
  const darkRatio = contrast('000000', brand_accent_color)
  const lightRatio = contrast('ffffff', brand_accent_color)
  return darkRatio < lightRatio ? '#000000' : '#ffffff'
}

export const lightOrDarkHeader = (preferences?: Partial<ExplorePreferences>): 'light' | 'dark' => {
  return preferences?.version === 2
    ? !preferences?.checkout_background_url &&
      preferences?.checkout_overlay_opacity &&
      preferences?.checkout_overlay_opacity < 70
      ? lightOrDark(
          flattenedColor(
            preferences.checkout_overlay_color || '000000',
            preferences.brand_accent_color || 'ffffff',
            preferences?.checkout_overlay_opacity,
          ),
        )
      : lightOrDark(preferences?.checkout_overlay_color || '#ffffff')
    : lightOrDark(preferences?.brand_accent_color || '#ffffff')
}

export const getProgramBrandingCss = (preferences?: Partial<ExplorePreferences>) => {
  const brand_accent_color: string = getBrandColor({ ...preferences })
  const safe_accent_color: string = getSafeBrandColor({ ...preferences })
  const theme: 'dark' | 'light' = lightOrDark(brand_accent_color)
  const complementary_color: string = getComplementaryColor({ ...preferences })
  const jumbo_select_color = theme === 'dark' ? DefaultLight : DefaultDark
  const jumbo_select_second_color =
    lightOrDark(jumbo_select_color) === 'dark'
      ? lighten(jumbo_select_color, 10)
      : darken(jumbo_select_color, 10)
  const { R, G, B } = calculateRGB(brand_accent_color)
  return `
    .custom-branding {
      display: initial !important;
    }
    .custom-branding :focus {
      outline: ${addAlpha(brand_accent_color, 0.25)} auto 1px;
    }
    .custom-branding input:focus {
      outline: none;
    }
    .custom-branding a {
      color: ${safe_accent_color};
    }
    .custom-branding a:hover {
      color: ${
        theme === 'light' ? darken(brand_accent_color, 50) : lighten(brand_accent_color, 50)
      };
    }
    .custom-branding .btn {
      outline: 0;
      text-decoration: none;
    }
    .custom-branding .btn:focus, .custom-branding .btn.focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem ${addAlpha(brand_accent_color, 0.25)};
    }
    .custom-branding .btn.btn-link:focus, .custom-branding .btn.btn-link.focus {
      box-shadow: unset;
      text-decoration: underline;
      text-decoration-thickness: 3px;
      text-decoration-color: ${DefaultDark};
      text-decoration-style: dotted;
    }
    .custom-branding .bg-primary {
      background-color: ${brand_accent_color} !important;
    }
    .custom-branding .btn-primary {
      color: ${complementary_color};
      background-color: ${brand_accent_color};
      border-color: ${brand_accent_color};
    }
    .custom-branding .btn-primary.disabled, .custom-branding .btn-primary:disabled {
      color: ${complementary_color};
      background-color: ${brand_accent_color};
      border-color: ${brand_accent_color};
    }
    .custom-branding .btn-primary:not(:disabled):not(.disabled):active, .custom-branding .btn-primary:not(:disabled):not(.disabled).active {
      color: ${complementary_color};
      background-color: ${
        theme === 'light' ? darken(brand_accent_color, 30) : lighten(brand_accent_color, 30)
      };
      border-color: ${
        theme === 'light' ? darken(brand_accent_color, 20) : lighten(brand_accent_color, 20)
      };
    }
    .custom-branding .btn-primary:not(:disabled):not(.disabled):active:focus, .custom-branding .btn-primary:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 0.2rem ${addAlpha(brand_accent_color, 0.5)};
    }
    .custom-branding .btn-primary:hover {
      color: ${complementary_color};
      background-color: ${
        theme === 'light' ? darken(brand_accent_color, 30) : lighten(brand_accent_color, 30)
      };
      border-color: ${
        theme === 'light' ? darken(brand_accent_color, 20) : lighten(brand_accent_color, 20)
      };
    }
    .custom-branding .btn-primary:focus, .custom-branding .btn-primary.focus {
      color:  ${complementary_color};
      background-color: ${
        theme === 'light' ? darken(brand_accent_color, 30) : lighten(brand_accent_color, 30)
      };
      border-color: ${
        theme === 'light' ? darken(brand_accent_color, 20) : lighten(brand_accent_color, 20)
      };
      box-shadow: 0 0 0 0.2rem ${addAlpha(brand_accent_color, 0.5)};
    }
    .custom-branding .btn-link {
      color: ${safe_accent_color};
    }
    .custom-branding .btn-link:hover {
      color: ${theme === 'light' ? darken(safe_accent_color, 30) : lighten(safe_accent_color, 30)};
    }
    .custom-branding .btn.btn-outline-primary {
      color: ${safe_accent_color};
      background-color: transparent;
      border-color: ${brand_accent_color};
    }
    .custom-branding .btn-outline-primary:not(:disabled):not(.disabled):active, .custom-branding .btn-outline-primary:not(:disabled):not(.disabled).active {
      color: ${complementary_color};
      background-color: ${brand_accent_color};
      border-color: ${brand_accent_color};
    }
    .custom-branding .btn-outline-primary:hover {
      color: ${complementary_color};
      background-color: ${brand_accent_color};
      border-color: ${brand_accent_color};
    }
    .custom-branding .dropdown-item.active, .custom-branding .dropdown-item:active {
      background-color: ${brand_accent_color};
    }
    .custom-branding .form-control:focus {
      border-color: ${
        theme === 'light' ? darken(brand_accent_color, 30) : lighten(brand_accent_color, 30)
      };
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(${R},${G},${B}, 0.25);
    }
    .custom-branding .custom-select:focus {
      border-color: ${
        theme === 'light' ? darken(brand_accent_color, 30) : lighten(brand_accent_color, 30)
      };
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(${R},${G},${B}, 0.25);
    }
    .custom-branding .nav-tabs .nav-link.active, .custom-branding .nav-tabs .nav-item.show .nav-link {
      color: ${brand_accent_color};
      border-color: transparent transparent ${brand_accent_color};
      text-decoration: none;
    }
    .custom-branding .nav .nav-item .nav-link {
      color: ${brand_accent_color};
      background-color: transparent;
      border: 1px solid transparent;
      text-decoration: none;
      font-size: 110%;
    }
    .custom-branding .nav .nav-item .nav-link.active {
      font-weight: 700 !important;
    }
    .custom-branding .nav-tabs .nav-link:hover, .custom-branding .nav-tabs .nav-item.show .nav-link:hover {
      border-color: transparent transparent ${brand_accent_color};
    }
    .custom-branding .navbar-collapse.collapse.show .nav-item .nav-link, .custom-branding .navbar-collapse.show .nav-item .nav-link.active {
      color: ${complementary_color};
    }
    .custom-branding .navbar-collapse.collapse.show ~ .navbar-toggler {
      color: ${complementary_color};
    }
    .custom-branding .text-primary {
      color: ${safe_accent_color} !important;
    }
    .custom-branding .text-complementary {
      color: ${complementary_color} !important;
    }
    .custom-branding .card-select.styled.selected {
      background-color: ${lighten(brand_accent_color, 10)};
      color: ${jumbo_select_color};
    }
    .custom-branding .card-select.styled.selected .custom-control-label .form-text {
      color: ${jumbo_select_second_color};
    }
    .custom-branding .badge-primary {
      color: ${complementary_color};
      background-color: ${brand_accent_color};
    }
    .custom-branding .custom-control-input:checked ~ .custom-control-label::before {
      color: ${complementary_color};
      border-color: ${brand_accent_color};
      background-color: ${brand_accent_color};
    }
    .custom-branding .custom-control-input:focus ~ .custom-control-label::before {
      box-shadow: 0 0 0 0.2rem ${addAlpha(brand_accent_color, 0.4)};
    }
    .custom-branding .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
      color: ${complementary_color};
      background-color: ${addAlpha(brand_accent_color, 0.4)};
      border-color: ${addAlpha(brand_accent_color, 0.4)};
    }
    .custom-branding .border-primary {
      border-color: ${brand_accent_color} !important;
    }
    .custom-branding .custom-preregister-flag {
      margin-left: auto;
      margin-right: auto;
      position: relative;
      background-color: ${brand_accent_color} !important;
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      color: ${complementary_color} !important;
      padding: 0.15rem 1rem 0rem 1rem;
    }
    .auth-container {
      background: linear-gradient(25deg, ${getBrandColor(preferences)} 50%, ${DefaultLight} 50%)
    }
    @media (max-height: 25rem) {
      .auth-container {
        background: linear-gradient(5deg, ${getBrandColor(preferences)} 40%, ${DefaultLight} 40%);
      }
    }
    .custom-branding .list-group-item {
      color: ${DefaultDark} !important;
    }
    .custom-branding .list-group-item.active {
      color: ${brand_accent_color} !important;
      background-color: ${addAlpha(brand_accent_color, 0.25)} !important;
      border-color: ${addAlpha(brand_accent_color, 0.25)} !important;
    }
  `
}
