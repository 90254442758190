import { useRouter } from 'next/router'
import { Dispatch, useState } from 'react'
import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import {
  Attendee,
  AttendeeMergeParams,
  AttendeeProfile,
  CustomerApi,
  ErrorResponse,
  AttendeeList,
} from '@citruscamps/citrus-client'
import { DefaultMainPagination, UnlimitedPagination } from '../constants/pagination'
import { Pagination } from '../interfaces/pagination'
import translations from '../translations/en.json'
import { generateApiConfig } from '../utils/client-config'
import { generateBaseKey, generateItemKey, generateListKey } from '../utils/key-generator'
import { useRequestHandler } from './useRequestHandler'
import { useLocalStorage } from './useLocalStorage'
import { useSearchValue } from './useSearchValue'
import { useToast } from './useToast'

const DefaultFetchProps: AttendeeFetchProps = {
  sort: 'first_name',
  order: 'ASC',
  filter: {},
}

export interface AttendeeFilterProps {
  age?: number
}

export interface AttendeeFetchProps {
  sort?: string
  order?: 'ASC' | 'DESC'
  filter: AttendeeFilterProps
}

interface IProps {
  programId?: string
  pagination?: Pagination
  fetchProps?: AttendeeFetchProps
  overwriteFilters?: boolean
  fetchAll?: boolean
  enabled?: boolean
}

interface FetchAttendees {
  data: Attendee[]
  error: ErrorResponse | null
  fetchProps: AttendeeFetchProps
  isError: boolean
  isLoading: boolean
  pagination: Pagination
  search: string
  handleMerge: (values: AttendeeMergeParams) => Promise<Attendee | undefined>
  handleConnectAttendee: (value: Attendee, customerProfileId: string) => Promise<void>
  setPagination: Dispatch<Pagination>
  setSearch: Dispatch<string>
  setFetchProps: (value?: AttendeeFetchProps) => void
}

export const useFetchAttendees = ({
  programId,
  pagination: initialPagination = DefaultMainPagination,
  enabled = true,
  fetchProps: initialFetchProps = DefaultFetchProps,
}: IProps): FetchAttendees => {
  const { requestHandler } = useRequestHandler()
  const { setToast } = useToast()
  const queryClient = useQueryClient()
  const [pagination, setPagination] = useState<Pagination>(initialPagination)
  const [fetchProps, setFetchProps] = useState<AttendeeFetchProps>(initialFetchProps)
  const { search, deferSearch, setSearch, isSearching } = useSearchValue('', {
    onDefer: () => setPagination(initialPagination),
  })
  const queryKeys = generateListKey({
    type: 'attendee',
    pagination,
    sort: fetchProps.sort,
    order: fetchProps.order,
    query: { program_id: programId, ...fetchProps.filter },
    search: deferSearch,
  })
  const {
    isInitialLoading: isLoading,
    isError,
    data: resp,
    error,
  } = useQuery<AttendeeList, ErrorResponse>(
    queryKeys,
    async ({ signal }) => {
      const client = new CustomerApi(generateApiConfig())
      const skip = pagination.limit ? pagination.page * pagination.limit : undefined
      const response = await requestHandler<AttendeeList>(
        () =>
          client.findCustomerAttendees(
            {
              limit: pagination.limit,
              skip,
              order: fetchProps.order,
              sort: fetchProps.sort,
              search: deferSearch || undefined,
              age: fetchProps.filter?.age || undefined,
            },
            { signal },
          ),
        {
          toast: { setToast },
        },
      )
      response.data.forEach((item) =>
        queryClient.setQueryData(
          generateItemKey({
            type: 'attendee',
            id: item.id,
          }),
          item,
        ),
      )
      return response
    },
    { enabled },
  )

  const handleMerge = async (values: AttendeeMergeParams): Promise<Attendee | undefined> => {
    const client = new CustomerApi(generateApiConfig())
    if (programId) {
      try {
        const _attendee = await requestHandler<Attendee>(
          () =>
            client.mergeAttendeeCustomerAttendee({
              attendee_id: values.merge_from,
              AttendeeMergeParams: values,
            }),
          {
            toast: {
              setToast,
            },
          },
        )
        await queryClient.invalidateQueries(generateBaseKey({ type: 'attendee' }))
        return _attendee
      } catch (e: any) {
        setToast({
          header: (translations as any)['error.default_toast_header'],
          message: e.message || (translations as any)['error.default_toast_message'],
          type: 'danger',
        })
        throw e
      }
    }
    return
  }

  const handleConnectAttendee = async (
    value: Attendee,
    customerProfileId: string,
  ): Promise<void> => {
    const attendeeClient = new CustomerApi(generateApiConfig())
    if (programId && customerProfileId) {
      try {
        await requestHandler<AttendeeProfile>(() =>
          attendeeClient.createAttendeeProfile({
            attendee_id: value.id,
            program_id: programId,
            AttendeeProfileCreateParams: {
              program_id: programId,
              attendee_id: value.id,
              primary_contact_id: customerProfileId,
            },
          }),
        )
        await queryClient.invalidateQueries(
          generateBaseKey({
            type: 'attendee',
          }),
        )
        await queryClient.invalidateQueries(
          generateBaseKey({
            type: 'contact',
          }),
        )
      } catch (e: any) {
        setToast({
          header: (translations as any)['error.default_toast_header'],
          message: e?.message || (translations as any)['error.default_toast_message'],
          type: 'danger',
        })
        throw e
      }
    } else {
      setToast({
        header: (translations as any)['error.default_toast_header'],
        message: (translations as any)['error.default_toast_message'],
        type: 'danger',
      })
    }
  }

  const _setFetchProps = (val?: AttendeeFetchProps) => {
    setPagination(initialPagination)
    return setFetchProps(val || DefaultFetchProps)
  }

  return {
    data: resp?.data || [],
    error,
    fetchProps,
    isError,
    isLoading: isLoading || isSearching,
    pagination: {
      ...pagination,
      count: resp?.count || pagination.count,
      total: resp?.total || pagination.total,
      page: resp?.page || pagination.page,
      page_count: resp?.page_count || pagination.page_count,
    },
    search,
    handleMerge,
    handleConnectAttendee,
    setFetchProps: _setFetchProps,
    setPagination,
    setSearch,
  }
}

interface FetchInfiniteAttendees {
  data: Attendee[]
  error: ErrorResponse | null
  fetchProps: AttendeeFetchProps
  isError: boolean
  isLoading: boolean
  pagination: Pagination
  search: string
  isSearching: boolean
  hasNextPage: boolean
  isFetching: boolean
  isFetchingNextPage: boolean
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<AttendeeList, ErrorResponse>>
  handleMerge: (values: AttendeeMergeParams) => Promise<Attendee | undefined>
  handleConnectAttendee: (value: Attendee, customerProfileId: string) => Promise<void>
  setSearch: Dispatch<string>
  setFetchProps: (value?: AttendeeFetchProps) => void
}

export const useFetchInfiniteAttendees = ({
  programId,
  pagination = DefaultMainPagination,
  overwriteFilters,
  enabled = true,
  fetchAll = false,
  ...props
}: IProps): FetchInfiniteAttendees => {
  const { requestHandler } = useRequestHandler()
  const { setToast } = useToast()
  const queryClient = useQueryClient()
  const { asPath } = useRouter()
  const initialFetchProps: AttendeeFetchProps = props.fetchProps || DefaultFetchProps
  const [fetchProps, setFetchProps] = useLocalStorage<AttendeeFetchProps>(
    `attendee-filter-${asPath}`,
    initialFetchProps,
    overwriteFilters,
  )
  const { search, deferSearch, setSearch, isSearching } = useSearchValue('', {})
  const queryKeys = generateListKey({
    type: 'attendee',
    pagination: UnlimitedPagination,
    sort: fetchProps.sort,
    order: fetchProps.order,
    query: { program_id: programId, ...fetchProps.filter },
    search: deferSearch,
  })
  const {
    data: resp,
    error,
    isInitialLoading: isLoading,
    isError,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage = false,
  } = useInfiniteQuery<AttendeeList, ErrorResponse>(
    queryKeys,
    async ({ signal, pageParam: pg }) => {
      pg = pg || pagination
      if (!programId) {
        throw new Error('Unable to fetch data')
      }
      const client = new CustomerApi(generateApiConfig())
      const skip = pg.limit ? pg.page * pg.limit : undefined
      const resp = await requestHandler<AttendeeList>(
        () =>
          client.findCustomerAttendees(
            {
              limit: pagination.limit,
              skip,
              order: fetchProps.order,
              sort: fetchProps.sort,
              search: deferSearch || undefined,
              age: fetchProps.filter?.age || undefined,
            },
            { signal },
          ),
        {
          toast: { setToast },
        },
      )
      resp.data.forEach((item) =>
        queryClient.setQueryData(
          generateItemKey({
            type: 'attendee',
            id: item.id,
          }),
          item,
        ),
      )
      return resp
    },
    {
      getNextPageParam: (resp: AttendeeList): Pagination | undefined => {
        if (!resp.page_count || resp.page < resp.page_count - 1) {
          return {
            ...pagination,
            count: resp?.count,
            total: resp?.total,
            page: resp?.page + 1,
            page_count: resp?.page_count,
          }
        }
        return undefined
      },
      getPreviousPageParam: (resp: AttendeeList): Pagination | undefined => {
        if (!resp.page_count || resp.page <= resp.page_count) {
          return {
            ...pagination,
            count: resp?.count,
            total: resp?.total,
            page: resp?.page - 1,
            page_count: resp?.page_count,
          }
        }
        return undefined
      },
      enabled: !!programId && !!enabled,
    },
  )

  const handleMerge = async (values: AttendeeMergeParams): Promise<Attendee | undefined> => {
    const client = new CustomerApi(generateApiConfig())
    if (programId) {
      try {
        const _attendee = await requestHandler<Attendee>(
          () =>
            client.mergeAttendeeCustomerAttendee({
              attendee_id: values.merge_from,
              AttendeeMergeParams: values,
            }),
          {
            toast: {
              setToast,
            },
          },
        )
        await queryClient.invalidateQueries(generateBaseKey({ type: 'attendee' }))
        return _attendee
      } catch (e: any) {
        setToast({
          header: (translations as any)['error.default_toast_header'],
          message: e.message || (translations as any)['error.default_toast_message'],
          type: 'danger',
        })
        throw e
      }
    }
    return
  }

  const handleConnectAttendee = async (
    value: Attendee,
    customerProfileId: string,
  ): Promise<void> => {
    const attendeeClient = new CustomerApi(generateApiConfig())
    if (programId && customerProfileId) {
      try {
        await requestHandler<AttendeeProfile>(() =>
          attendeeClient.createAttendeeProfile({
            attendee_id: value.id,
            program_id: programId,
            AttendeeProfileCreateParams: {
              program_id: programId,
              attendee_id: value.id,
              primary_contact_id: customerProfileId,
            },
          }),
        )
        await queryClient.invalidateQueries(
          generateBaseKey({
            type: 'attendee',
          }),
        )
        await queryClient.invalidateQueries(
          generateBaseKey({
            type: 'contact',
          }),
        )
      } catch (e: any) {
        setToast({
          header: (translations as any)['error.default_toast_header'],
          message: e?.message || (translations as any)['error.default_toast_message'],
          type: 'danger',
        })
        throw e
      }
    } else {
      setToast({
        header: (translations as any)['error.default_toast_header'],
        message: (translations as any)['error.default_toast_message'],
        type: 'danger',
      })
    }
  }

  const _setFetchProps = (val?: AttendeeFetchProps) => {
    return setFetchProps(val || DefaultFetchProps)
  }

  if (!(isFetching || isFetchingNextPage || isLoading) && hasNextPage && fetchAll) {
    fetchNextPage()
  }

  const response = [...(resp?.pages || [])].pop()

  return {
    data: (resp?.pages || []).reduce<Attendee[]>((list, p) => [...list, ...p.data], []) || [],
    error,
    fetchProps,
    hasNextPage,
    isError,
    isFetching,
    isFetchingNextPage,
    isLoading: isLoading || isSearching,
    pagination: {
      ...pagination,
      count: response?.count || pagination.count,
      total: response?.total || pagination.total,
      page: response?.page || pagination.page,
      page_count: response?.page_count || pagination.page_count,
    },
    search,
    isSearching,
    handleMerge,
    handleConnectAttendee,
    setSearch,
    fetchNextPage,
    setFetchProps: _setFetchProps,
  }
}
