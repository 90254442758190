import {
  Attendee,
  AttendeeProfile,
  Customer,
  CustomerProfile,
  ExploreEvent,
  ExploreMembershipPackage,
  ExploreProgram,
  PaymentMethod,
  ProgramEventType,
  RefundPolicy,
  RefundRequestReason,
  User,
} from '@citruscamps/citrus-client'
import { DateTime, Interval } from 'luxon'

export const formatFullName = (
  person?:
    | Partial<Attendee>
    | Partial<AttendeeProfile>
    | Partial<Customer>
    | Partial<CustomerProfile>
    | Partial<User>,
): string => {
  return [person?.first_name, person?.last_name].filter((v?: string) => !!v).join(' ')
}

export const formatAttendeeLabel = (attendee: Partial<Attendee>) => {
  const gender: string = formatGender(attendee)
  const age: string = formatAge(attendee)
  return [gender, age]
    .filter((i) => !!i)
    .join(', ')
    .trim()
}

export const formatAge = (attendee: Partial<Attendee>): string => {
  if (!attendee.date_of_birth) return ''
  const birthDateTime = DateTime.fromFormat(attendee.date_of_birth, 'yyyy-MM-dd')
  const yearsOld: number = Interval.fromDateTimes(birthDateTime, DateTime.local()).toDuration(
    'years',
  ).years
  const monthsOld: number = Interval.fromDateTimes(birthDateTime, DateTime.local()).toDuration(
    'months',
  ).months
  return yearsOld >= 1
    ? `${Math.floor(yearsOld)} year${yearsOld !== 1 ? 's' : ''} old`
    : `${Math.floor(monthsOld)} month${monthsOld > 1 ? 's' : ''} old`
}

export const relationLabel = (attendee: Attendee, related: boolean = true): string => {
  let relationship = ''
  let age = ''
  let gender = ''
  switch (attendee.relations[0].type) {
    case 'parent':
      if (attendee.gender === 'female') {
        relationship = `${related ? 'Your' : 'Their'} daughter`
      } else if (attendee.gender === 'male') {
        relationship = `${related ? 'Your' : 'Their'} son`
      } else {
        relationship = `${related ? 'Your' : 'Their'} child`
      }
      break
    case 'grandparent':
      if (attendee.gender === 'female') {
        relationship = `${related ? 'Your' : 'Their'} granddaughter`
      } else if (attendee.gender === 'male') {
        relationship = `${related ? 'Your' : 'Their'} grandson`
      } else {
        relationship = `${related ? 'Your' : 'Their'} grandchild`
      }
      break
    case 'guardian':
      relationship = `${related ? 'Your' : 'Their'} dependent`
      break
    case 'spouse':
      relationship = `${related ? 'Your' : 'Their'} spouse`
      break
    case 'partner':
      relationship = `${related ? 'Your' : 'Their'} partner`
      break
    case 'sibling':
      if (attendee.gender === 'female') {
        relationship = `${related ? 'Your' : 'Their'} sister`
      } else if (attendee.gender === 'male') {
        relationship = `${related ? 'Your' : 'Their'} brother`
      } else {
        relationship = `${related ? 'Your' : 'Their'} sibling`
      }
      break
    case 'friend':
      relationship = `${related ? 'Your' : 'Their'} friend`
      break
    case 'social_worker':
      relationship = `${related ? 'Your' : 'Their'} child in care`
      break
    case 'self':
      relationship = related ? 'Yourself' : 'Themself'
      break
    case 'other':
      relationship =
        attendee.relations[0].type[0].toUpperCase() +
        attendee.relations[0].type.slice(1).toLowerCase()
      break
  }
  if (attendee.date_of_birth) {
    const birthDateTime = DateTime.fromFormat(attendee.date_of_birth, 'yyyy-MM-dd')
    const yearsOld: number = Interval.fromDateTimes(birthDateTime, DateTime.local()).toDuration(
      'years',
    ).years
    if (birthDateTime.isValid) {
      if (yearsOld && attendee.relations[0].type !== 'social_worker') {
        age = `${Math.floor(yearsOld)} years old`
      } else {
        age = `Date of Birth: ${attendee.date_of_birth}`
      }
    }
  }
  return [relationship, age, gender].filter(Boolean).join(', ').trim()
}

export const formatGender = (attendee: Partial<Attendee>): string => {
  switch (attendee.gender) {
    case 'female':
      return 'Female'
    case 'male':
      return 'Male'
    case 'not_specified':
      return 'Not specified'
    default:
      return 'Non-binary'
  }
}

export const formatHealthCard = (cardNumber: string | undefined): string => {
  if (!cardNumber) {
    return 'No health card'
  }
  return `••• ••• ${cardNumber.substring(6)}`
}

export const formatRefundPolicy = (policy: RefundPolicy, type: ProgramEventType): string => {
  switch (type === 'session' ? policy.session_cancellation_policy : policy.camp_refund_policy) {
    case 'one_day_policy':
      return `${type === 'camp' ? 'Refunds' : 'Cancellations'} up to 24 hours before event`
    case 'three_day_policy':
      return `${type === 'camp' ? 'Refunds' : 'Cancellations'} up to 72 hours before event`
    case 'seven_day_policy':
      return `${type === 'camp' ? 'Refunds' : 'Cancellations'} up to 7 days before event`
    case 'thirty_day_policy':
      return `${type === 'camp' ? 'Refunds' : 'Cancellations'} up to 30 days before event`
    case 'no_refunds':
      return `No ${type === 'camp' ? 'Refunds' : 'Cancellations'}`
    case 'not_defined':
    default:
      return `Contact the organizer to request a ${type === 'camp' ? 'refund' : 'cancellation'}.`
  }
}

export const formatRefundRequest = (request: RefundRequestReason): string => {
  switch (request) {
    case 'covid_19':
      return 'COVID-19'
    case 'event_cancelled':
      return 'Event cancelled or postponed'
    case 'duplicate_order':
      return 'Duplicate order'
    case 'no_longer_able_to_attend':
      return 'No longer able to attend'
    case 'dissatisfied_with_purchase':
      return 'Dissatisfied with purchase'
    case 'purchased_the_wrong_item':
      return 'Purchased the wrong item'
    case 'other':
    default:
      return 'Other reason not listed'
  }
}

export const formatCheckoutMethod = (
  value: string | PaymentMethod,
  language: string = navigator?.language,
): string => {
  if (!value) return value
  switch (value) {
    case 'citrus':
      return 'Online payment'
    case 'cash':
      return 'Cash'
    case 'check':
      switch (language) {
        case 'en-CA':
          return 'Cheque'
        default:
          return 'Check'
      }
    case 'invoice':
      return 'Invoice'
    case 'transfer':
      return 'Online transfer'
    case 'account_balance':
      return 'Account balance'
    default:
      return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase().split('_').join(' ')
  }
}

export const pluralize = (value: number): string => {
  if (value > 1) {
    return 's'
  } else return ''
}

export const formatList = (values: string[], type: 'and' | 'or' = 'and') => {
  if (values.length <= 1) {
    return values[0] || ''
  } else if (values.length > 2) {
    return values.slice(0, -1).join(', ').concat(`, ${type} `, values.slice(-1).toString())
  } else {
    return values.slice(0, -1).join(', ').concat(` ${type} `, values.slice(-1).toString())
  }
}

export const parseDate = (date: string | Date, timezone?: string): DateTime | undefined =>
  date && date instanceof Date
    ? DateTime.fromJSDate(date, {
        zone: timezone,
      })
    : date && typeof date === 'string'
    ? DateTime.fromJSDate(new Date(date), {
        zone: timezone,
      })
    : undefined

export const formatEventType = (program: ExploreProgram) => {
  if (program.types.includes('sports')) return 'Training'
  if (program.types.includes('education')) return 'Instruction'
  return 'Activities'
}

export const formatEventDateRange = (event: ExploreEvent): string => {
  const startsAt: DateTime | undefined = parseDate(event.starts_at, event.timezone)
  const endsAt: DateTime | undefined = parseDate(event.ends_at, event.timezone)
  const dateRange: string = [
    startsAt?.toFormat('LLL d'),
    startsAt && !endsAt?.hasSame(startsAt, 'year') && startsAt?.toFormat(', yyyy'),
    startsAt && !endsAt?.hasSame(startsAt, 'day') && ' - ',
    startsAt && (!endsAt?.hasSame(startsAt, 'year') || !endsAt?.hasSame(startsAt, 'month'))
      ? endsAt?.toFormat('DD')
      : startsAt && !endsAt?.hasSame(startsAt, 'day')
      ? endsAt?.toFormat('d, yyyy')
      : endsAt?.toFormat(', yyyy'),
  ]
    .filter((d) => !!d)
    .join('')
  return dateRange
}

export const formatEventTimeRange = (event: ExploreEvent): string => {
  const startsAt: DateTime | undefined = parseDate(event.starts_at, event.timezone)
  const endsAt: DateTime | undefined = parseDate(event.ends_at, event.timezone)
  const timeRange: string = [
    startsAt ? startsAt.toFormat('t') : '',
    endsAt ? endsAt.toFormat('t') : '',
  ]
    .filter((d) => !!d)
    .join(' - ')
  return timeRange
}

export const formatEventDateTimeRange = (event: ExploreEvent): string => {
  const startsAt: DateTime | undefined = parseDate(event.starts_at, event.timezone)
  const endsAt: DateTime | undefined = parseDate(event.ends_at, event.timezone)
  if (event.is_series) {
    return formatEventDateRange(event)
  }
  if (endsAt && startsAt?.hasSame(endsAt, 'day')) {
    return `${formatEventDateRange(event)} at ${formatEventTimeRange(event)}`
  }
  if (startsAt && endsAt) {
    return `${startsAt.toLocaleString(DateTime.DATETIME_MED)} to ${endsAt.toLocaleString(
      DateTime.DATETIME_MED,
    )}`
  }
  return 'Invalid Date'
}

export const formatAllocatedPeriod = (pack: ExploreMembershipPackage) => {
  if (pack.is_unlimited) {
    return 'Unlimited sessions'
  } else if (pack.allocated_amount && pack.allocated_period) {
    return `${pack.allocated_amount} session${pluralize(pack.allocated_amount)} per ${
      pack.allocated_period
    }`
  } else return undefined
}

export const formatPhoneNumber = (value?: string) => {
  if (!value) return value
  if (typeof value !== 'string') value = `${value}`
  try {
    if (value) {
      let p: Array<string> = value.replace(/\D/g, '').match(/(\d{1})(\d{3})(\d{3})(\d{4})/) || []
      if (p.length === 5) {
        return `${p[1]} (${p[2]}) ${p[3]}-${p[4]}`
      }
      p = value.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/) || []
      if (p && p.length === 4) {
        return `1 (${p[1]}) ${p[2]}-${p[3]}`
      }
      return `+${value}`
    }
  } catch {
    return `+${value}`
  }
  return `+${value}`
}
