import { useState } from 'react'

let timeout: number | undefined

interface IProps {
  wait?: number
  onDefer?: (value: React.SetStateAction<string>) => void | Promise<void>
}

interface IValue {
  search: string
  deferSearch: string
  isSearching: boolean
  setSearch: React.Dispatch<React.SetStateAction<string>>
}

export function useSearchValue(
  initialValue?: string | (() => string),
  { wait = 700, onDefer: handleDefer }: IProps = {},
): IValue {
  const [search, setValue] = useState<string>(initialValue || '')
  const [deferSearch, setDeferSearch] = useState<string>(initialValue || '')
  const setSearch = (newValue: React.SetStateAction<string>): void => {
    if (timeout) window.clearTimeout(timeout)
    setValue(newValue)
    timeout = window.setTimeout(() => {
      setDeferSearch(newValue)
      handleDefer?.(newValue)
    }, wait)
  }
  return { search, deferSearch, isSearching: search !== deferSearch, setSearch }
}
