import React, { useRef } from 'react'

interface IProps
  extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  placeholder?: string
}

export const Icon = (props: IProps): React.ReactElement => {
  let { src, alt, ...imgProps } = props
  const ref = useRef<HTMLImageElement>(null)
  const placeholder = props?.placeholder || `${process.env.PUBLIC_URL}/imgs/placeholder-image.jpg`
  return (
    <img
      ref={ref}
      {...imgProps}
      src={src || placeholder}
      alt={alt}
      onError={function (e: React.SyntheticEvent<HTMLImageElement, Event>) {
        if (ref.current) {
          ref.current.onerror = null
          ref.current.src = placeholder
        }
      }}
    />
  )
}
