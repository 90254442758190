import React from 'react'

export const FormSkeleton = () => (
  <>
    <div className="h4 w-50 animate"></div>
    <div className="row form-group mt-4">
      <div className="col">
        <label className="w-25 animate"></label>
        <div className="form-control animate" />
      </div>
    </div>
    <div className="row form-group mt-4">
      <div className="col">
        <label className="w-25 animate"></label>
        <div className="form-control animate" />
      </div>
    </div>
    <div className="row form-group mt-4">
      <div className="col">
        <div className="btn animate" />
      </div>
    </div>
  </>
)
