import {
  faCheckCircle,
  faExclamationCircle,
  faInfoCircle,
  faTimes,
  faTimesCircle,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { DefaultToastFade, DefaultToastTimeout } from '../../hooks/useToast'
import { TruncatedText } from '../TruncatedText/TruncatedText'

export interface ToastProps {
  autoClose?: boolean
  timeout?: number
  style?: React.CSSProperties
  type: 'success' | 'info' | 'warning' | 'danger'
  header: string
  message: string
  onClose?: () => void
}

export const Toast = (props: ToastProps): React.ReactElement | null => {
  const timeout = props.timeout || DefaultToastTimeout
  const { header, style, type } = props
  let autoClose = typeof props.autoClose !== 'undefined' ? props.autoClose : true
  const defaultClass = `toast show toast-${type} mt-4 mx-auto mx-md-4`
  const [showToast, setShowToast] = useState<boolean>(true)
  const [toastClass, setToastClass] = useState<string>(defaultClass)
  const [progress, setProgress] = useState<number>(100)
  const [intervalId, setIntervalId] = useState<number>(0)

  const onClose = (): void => {
    const fadeClass = `toast show toast-${type} mt-4 mx-auto mx-md-4 fade-out-right`
    setToastClass(fadeClass)

    setTimeout(() => {
      setShowToast(false)
      if (props?.onClose) {
        props.onClose()
      }
    }, DefaultToastFade)
  }

  useEffect(() => {
    setShowToast(true)
    setProgress(100)
    setToastClass(defaultClass)
    if (autoClose) {
      if (intervalId) {
        clearInterval(intervalId)
      }
      let count: number = 0
      let interval: number = Math.round(timeout / 500)
      const updateProgress = () => {
        count++
        let progress: number = Math.round(((timeout - interval * count) / timeout) * 100)
        setProgress(progress > 0 ? progress : 0)
      }
      const _intervalId = window.setInterval(updateProgress, interval)
      setIntervalId(_intervalId)
      setTimeout(() => {
        clearInterval(_intervalId)
        onClose()
      }, interval * 500 + interval)
    }
    return clearInterval(intervalId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, autoClose])

  const successIcon = faCheckCircle,
    infoIcon = faInfoCircle,
    warningIcon = faExclamationCircle,
    dangerIcon = faTimesCircle
  const icon =
    type === 'success'
      ? successIcon
      : type === 'info'
      ? infoIcon
      : type === 'warning'
      ? warningIcon
      : type === 'danger'
      ? dangerIcon
      : infoIcon

  if (showToast) {
    return (
      <div className="toaster">
        <div className={toastClass} style={style}>
          <div className="toast-header">
            <FontAwesomeIcon icon={icon} size="2x" className="toast-icon" />
            <div className="border-left ml-3 px-3 d-grid w-100">
              <TruncatedText className="font-weight-bold">{header}</TruncatedText>
              <div className="toast-message">{props.message}</div>
            </div>
            <button type="button" className="close" onClick={onClose}>
              <FontAwesomeIcon icon={faTimes} size="sm" className="text-muted" />
            </button>
          </div>
          {autoClose ? (
            <div className="progress mb-0 rounded-0">
              <div className="progress-bar" style={{ width: `${progress}%` }} />
            </div>
          ) : null}
        </div>
      </div>
    )
  } else {
    return null
  }
}
