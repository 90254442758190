import React, { useState } from 'react'
import { useCookies } from 'react-cookie'
import { CookieSetOptions } from 'universal-cookie'
import { fromCookieValue, toCookieKey, toCookieValue } from '../utils/cookies'

export function useCookiePreferences<T>(
  keyName: string,
  initialValue?: T,
  options?: CookieSetOptions | undefined,
): [T, React.Dispatch<T | undefined>] {
  const _keyName = toCookieKey(keyName)
  const [state, setState] = useState<T | undefined>(initialValue)
  const [cookie, setCookie, removeCookie] = useCookies<string, Record<string, string>>([_keyName])
  const setValue: React.Dispatch<T | undefined> = (val: T | undefined) => {
    if (val) {
      try {
        setCookie(_keyName, toCookieValue(val), {
          path: '/',
          maxAge: 30 * 24 * 60 * 60,
          sameSite: true,
          ...options,
        })
        setState(val)
      } catch (err) {
        console.error(err)
      }
    } else {
      removeCookie(_keyName)
      setState(undefined)
    }
  }
  let value: T = (fromCookieValue(cookie[_keyName]) || initialValue) as T
  return [state || value, setValue]
}
