const keyPrefix = 'citrus'

export const toHex = (value: string) => {
  let hex = ''
  for (let i = 0; i < value.length; i++) {
    hex += '' + value.charCodeAt(i).toString(16)
  }
  return hex
}

const fromHex = (hex: string) => {
  hex = hex.toString()
  let str = ''
  for (let i = 0; i < hex.length; i += 2) {
    str += String.fromCharCode(parseInt(hex.substring(i, i + 2), 16))
  }
  return str
}

export function getHexValue<T = any>(keyValue: T): string {
  let value = ''
  try {
    if (keyValue && typeof keyValue !== 'string') {
      value = JSON.stringify(keyValue)
    } else if (typeof keyValue === 'string') {
      value = keyValue
    }
  } catch {
    if (typeof keyValue === 'string') {
      value = keyValue
    } else {
      value = ''
    }
  }
  return toHex(value)
}

export function getObjectValue<T = any>(binaryValue?: string): T | null {
  if (!binaryValue) return null
  const b = binaryValue ? fromHex(binaryValue) : null
  let value: T | null = null
  try {
    if (b) {
      value = JSON.parse(b)
    }
  } catch (e) {
    value = null
  }
  return value
}

export const fromCookieValue = (value: string, version: string = '2021-03-04'): any => {
  const obj = getObjectValue(value)
  return obj?.version === version ? obj.value : null
}

export const toCookieValue = (keyValue?: any, version: string = '2021-03-04'): string => {
  return getHexValue({ value: keyValue, version })
}

export const toCookieKey = (keyValue?: any): string => {
  const toSnakeCase = (str: string): string =>
    str
      .split(/(?=[A-Z])/)
      .join('_')
      .toLowerCase()
      .replace(/[-&\\/\\#,+()$~%.'":*?<>{}]/g, '_')
  return `__${keyPrefix}_${toSnakeCase(keyValue)}`
}
