import { DateTime } from 'luxon'
import Head from 'next/head'
import React, { Fragment } from 'react'
import { ExploreEvent } from '@citruscamps/citrus-client'
import {
  DESCRIPTION,
  IMAGE_URL,
  SITE_NAME,
  THEME_COLOR,
  TITLE,
  WEBSITE_URL,
} from '../../constants/website'
import { Address } from '../../utils/address'
import { parseDate, formatEventDateTimeRange } from '../../utils/formatters'

interface IProps {
  themeColor?: string
  title?: string
  description?: string
  keywords?: string[]
  url: string
  image?: string
  siteName?: string
  website?: string
  event?: ExploreEvent
}

export const Meta = (props: IProps) => {
  const {
    themeColor = THEME_COLOR,
    title = TITLE,
    keywords,
    url,
    image = IMAGE_URL,
    siteName = SITE_NAME,
    website = WEBSITE_URL,
    description = DESCRIPTION,
    event,
  } = props
  return (
    <Head>
      <meta charSet="utf-8" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon"></link>
      <meta name="theme-color" content={`#${themeColor}`} />
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="version" content={process.env.REACT_APP_VERSION} />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords?.join(', ') || ''}></meta>
      <link rel="canonical" href={url} />
      <meta name="apple-mobile-web-app-title" content="Citrus" />
      <meta name="application-name" content="Citrus" />

      {/*  Open Graph / Facebook -- */}
      <meta property="fb:app_id" content="1012361739501555" />
      <meta property="og:site_name" content={siteName || SITE_NAME} />
      <meta property="og:type" content={event ? 'events.event' : 'website'} />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={event?.summary || description} />
      <meta property="og:image" content={image} />

      {/*  Twitter -- */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@citruscamps" />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={event?.summary || description} />
      <meta name="twitter:image" content={image} />

      {(() => {
        if (!event) return null
        const address: Address | undefined =
          event.venues?.[0] && Address.fromObject(event.venues?.[0])
        const startsAt: DateTime | undefined = parseDate(event.starts_at, event.timezone)
        const endsAt: DateTime | undefined = parseDate(event.ends_at, event.timezone)
        const longitude: number | undefined = address?.geometry?.coordinates[0]
        const latitude: number | undefined = address?.geometry?.coordinates[1]
        const labels: { label: string; data: string }[] = []
        if (event.online_event) labels.push({ label: 'Where', data: 'Online' })
        if (address && !event.online_event)
          labels.push({ label: 'Where', data: address.toLocaleString(Address.PLACENAME) })
        if (!address && !event.online_event) labels.push({ label: 'Where', data: 'TBA' })
        labels.push({
          label: 'When',
          data: formatEventDateTimeRange(event),
        })
        return (
          <Fragment key={event.id}>
            {/* <!-- Unique Facebook Tags --> */}
            <meta property="og:determiner" content="an" />
            <meta property="og:locale" content="en_US" />
            {address && (
              <>
                <meta property="event:location:latitude" content={latitude?.toString()} />
                <meta property="event:location:longitude" content={longitude?.toString()} />
              </>
            )}
            <meta property="event:start_time" content={startsAt?.toISO() || undefined} />
            <meta property="event:end_time" content={endsAt?.toISO() || undefined} />
            {/* <!-- Slack Unfurling --> */}
            {labels.map(({ label, data }, i) => (
              <Fragment key={[label, data].join(':')}>
                <meta name={'twitter:label' + (i + 1)} {...({ value: label } as any)} />
                <meta name={'twitter:data' + (i + 1)} {...({ value: data } as any)} />
              </Fragment>
            ))}
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{
                __html: JSON.stringify({
                  '@context': 'https://schema.org',
                  url,
                  '@type': 'Event',
                  name: event.name,
                  startDate: event.starts_at,
                  endDate: event.ends_at,
                  eventStatus: `https://schema.org/${
                    event.status === 'canceled' ? 'EventCancelled' : 'EventScheduled'
                  }`,
                  eventAttendanceMode: `https://schema.org/${
                    event.online_event ? 'OnlineEventAttendanceMode' : 'OfflineEventAttendanceMode'
                  }`,
                  location: (() => {
                    if (event.online_event) {
                      return {
                        '@type': 'VirtualLocation',
                        url,
                      }
                    }
                    if (address) {
                      return {
                        '@type': 'Place',
                        name: address.address_line_1,
                        address: {
                          '@type': 'PostalAddress',
                          streetAddress:
                            address.place_type === 'poi'
                              ? address.address_line_2
                              : address.address_line_1,
                          addressLocality: address.city,
                          postalCode: address.postcode,
                          addressRegion: address.region_short_code,
                          addressCountry: address.country_short_code,
                        },
                      }
                    }
                    return undefined
                  })(),
                  image: [event.picture_url],
                  description: event?.summary,
                  offers: {
                    '@type': 'Offer',
                    url,
                    price:
                      event.order_availability.minimum_option_price?.major_value.toString() || '0',
                    priceCurrency: event.currency.toUpperCase(),
                    availability: `https://schema.org/${
                      event.order_availability?.is_sold_out ? 'SoldOut' : 'InStock'
                    }`,
                    validFrom: event.starts_at,
                  },
                  performer: {
                    '@type': 'Organization',
                    name: event.program.name,
                    url: website,
                  },
                  organizer: {
                    '@type': 'Organization',
                    name: event.program.name,
                    url: website,
                  },
                }),
              }}
            ></script>
          </Fragment>
        )
      })()}
    </Head>
  )
}
