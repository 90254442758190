import {
  AddressSource,
  CustomerAddress,
  CustomerAddressObjectType,
  GeocodeAddress,
  GeometryPoint,
  MapboxPlaceType,
  ProgramEventVenue,
  Venue,
  VenueObjectType,
} from '@citruscamps/citrus-client'

class AddressSettings {
  static defaultOrigin =
    (typeof window !== 'undefined' ? navigator?.languages?.[0]?.split('-')?.pop() : undefined) ||
    'CA'
}

export interface AddressFormatOptions {
  address?: 'full' | 'medium' | 'short'
  city?: 'full'
  region?: 'full' | 'short'
  postcode?: 'full'
  country?: 'full' | 'short'
  singleLine?: boolean
}

type IVenueAddress = Omit<Venue, 'object' | 'program_id' | 'address_line_1' | 'postcode'>
type ICustomerAddress = Omit<
  CustomerAddress,
  'object' | 'customer_id' | 'address_line_1' | 'postcode'
>

interface AddressConfig {
  origin?: string
}

export class Address implements IVenueAddress, ICustomerAddress {
  id: string
  source: AddressSource
  place_type?: MapboxPlaceType
  place_name: string
  geometry?: GeometryPoint
  address_line_1?: string
  address_line_2?: string
  city: string
  region: string
  /** The ISO 3166-2 region code for the returned feature. */
  region_short_code: string
  postcode?: string
  /** Generally recognized countries or, in some cases like Hong Kong, an area of quasi-national administrative status that has been given a designated country code under ISO 3166-1 */
  country: string
  /** The ISO 3166-1 country code for the returned feature. */
  country_short_code: string
  geocode_feature?: GeocodeAddress
  object: VenueObjectType | CustomerAddressObjectType
  customer_id?: string
  origin: string

  constructor({ origin }: AddressConfig = {}) {
    this.id = ''
    this.source = 'custom'
    this.place_type = undefined
    this.place_name = ''
    this.geometry = undefined
    this.address_line_1 = undefined
    this.address_line_2 = undefined
    this.city = ''
    this.region = ''
    this.region_short_code = ''
    this.postcode = undefined
    this.country = ''
    this.country_short_code = ''
    this.geocode_feature = undefined
    this.object = 'address'
    this.customer_id = undefined
    this.origin = origin || AddressSettings.defaultOrigin
  }

  static fromObject(
    values: ProgramEventVenue | Venue | CustomerAddress,
    { origin }: AddressConfig = {},
  ): Address {
    const address = new Address()
    address.id = values.id
    address.source = values.source
    address.place_type = values.place_type
    address.place_name = values.place_name
    address.geometry = values.geometry
    address.address_line_1 = values.address_line_1
    address.address_line_2 = values.address_line_2
    address.city = values.city
    address.region = values.region
    address.region_short_code = values.region_short_code?.split('-').pop()?.toUpperCase() || ''
    address.postcode = values.postcode?.toUpperCase()
    address.country = values.country
    address.country_short_code = values.country_short_code?.toUpperCase()
    address.geocode_feature = values.geocode_feature
    switch (values.object) {
      case 'program_event_venue':
        address.object = 'venue'
        address.id = values.venue_id
        break
      case 'venue':
        address.object = 'venue'
        break
      case 'address':
        address.object = 'address'
        address.customer_id = values.customer_id
    }

    return address
  }

  toVenue(program_id?: string): Venue {
    return {
      id: this.id,
      object: 'venue',
      program_id: program_id || '',
      source: this.source,
      place_type: this.place_type,
      place_name: this.place_name,
      geometry: this.geometry,
      address_line_1: this.address_line_1 || '',
      address_line_2: this.address_line_2,
      city: this.city,
      region: this.region,
      region_short_code: this.region_short_code,
      postcode: this.postcode || '',
      country: this.country,
      country_short_code: this.country_short_code,
      geocode_feature: this.geocode_feature,
    }
  }

  static fromGeocode(values: GeocodeAddress): Address {
    const address = new Address()
    address.id = values.id || ''
    address.source = 'mapbox'
    address.place_type = [...values.place_type].pop()
    if (address.place_type === 'poi') {
      address.address_line_1 = values.text || undefined
      address.address_line_2 = values.properties.address || undefined
    } else if (address.place_type === 'address') {
      address.address_line_1 = values.place_name.split(',').shift() || undefined
    }
    address.city =
      address.place_type === 'place'
        ? values?.text
        : values.context?.find((c) => c.id?.includes('place'))?.text || ''
    address.region =
      address.place_type === 'region'
        ? values?.text
        : values?.context?.find((c) => c.id?.includes('region'))?.text || ''
    address.region_short_code =
      values.context
        ?.find((c) => c.id?.includes('region'))
        ?.short_code?.split('-')
        .pop()
        ?.toUpperCase() || ''
    address.postcode =
      address.place_type === 'postcode'
        ? values?.text
        : values.context?.find((c) => c.id?.includes('postcode'))?.text?.toUpperCase() || undefined
    address.country =
      address.place_type === 'country'
        ? values?.text
        : values.context?.find((c) => c.id?.includes('country'))?.text || ''
    address.country_short_code =
      values.context?.find((c) => c.id?.includes('country'))?.short_code?.toUpperCase() || ''
    address.geometry = values.geometry
    address.geocode_feature = values
    address.place_name = values.place_name
      ? values.place_name
      : address.toLocaleString(Address.PLACENAME)
    return address
  }

  get isValid(): boolean {
    return (
      !!this.source &&
      (this.source === 'mapbox' || this.source === 'custom') &&
      !!this.city &&
      !!this.region &&
      !!this.region_short_code &&
      !!this.postcode &&
      !!this.country &&
      !!this.country_short_code &&
      !!this.object &&
      (this.object === 'address' || this.object === 'venue') &&
      (!!this.customer_id || this.object !== 'address')
    )
  }

  toLocaleString(options: AddressFormatOptions): string {
    const { address, city, region, postcode, country, singleLine } = options
    const items: string[] = []
    if (
      address === 'full' &&
      this.place_name &&
      (!this.address_line_1 || !this.place_name.includes(this.address_line_1)) &&
      (!this.city || !this.place_name.includes(this.city))
    ) {
      items.push(this.place_name)
      if (!singleLine) {
        items.push('\n')
      }
    }
    if (address === 'full' && this.address_line_1) {
      items.push(this.address_line_1)
      if (!singleLine) {
        items.push('\n')
      }
    }
    if (address === 'full' && this.address_line_2) {
      items.push(this.address_line_2)
      if (!singleLine) {
        items.push('\n')
      }
    }
    const locationItems: string[] = []
    if (city === 'full' && this.city) {
      locationItems.push(this.city)
    }
    const regionItems: string[] = []
    if (region === 'short' && this.region_short_code) {
      regionItems.push(this.region_short_code)
    } else if (region === 'full' && this.region) {
      regionItems.push(this.region)
    }
    if (postcode && this.postcode) {
      regionItems.push(this.postcode)
    }
    if (regionItems.length > 0) {
      locationItems.push(
        ['US', 'CA'].includes(this.country_short_code) || this.place_type === 'poi'
          ? regionItems.join(' ')
          : regionItems.reverse().join(' '),
      )
    }
    if (locationItems.length > 0 && singleLine) {
      items.push(...locationItems)
    } else if (locationItems.length > 0) {
      items.push(locationItems.join(', '))
      items.push('\n')
    }
    if (country === 'full' && this.country) {
      items.push(this.country)
    }
    return items.length === 0 ? 'Invalid Address' : items.join(singleLine ? ', ' : '')
  }

  static readonly MAILING_ADDRESS: AddressFormatOptions = {
    address: 'full',
    city: 'full',
    region: 'short',
    postcode: 'full',
    country: 'full',
    singleLine: false,
  }

  static readonly PLACENAME: AddressFormatOptions = {
    address: 'full',
    city: 'full',
    region: 'full',
    postcode: 'full',
    country: 'full',
    singleLine: true,
  }

  static readonly CITY_FULL: AddressFormatOptions = {
    address: undefined,
    city: 'full',
    region: undefined,
    postcode: undefined,
    country: undefined,
    singleLine: true,
  }

  static readonly CITY_REGION_SHORT: AddressFormatOptions = {
    address: undefined,
    city: 'full',
    region: 'short',
    postcode: undefined,
    country: undefined,
    singleLine: true,
  }

  static readonly CITY_REGION_FULL: AddressFormatOptions = {
    address: undefined,
    city: 'full',
    region: 'full',
    postcode: undefined,
    country: undefined,
    singleLine: true,
  }

  static readonly REGION_SHORT: AddressFormatOptions = {
    address: undefined,
    city: undefined,
    region: 'short',
    postcode: undefined,
    country: undefined,
    singleLine: true,
  }

  static readonly REGION_FULL: AddressFormatOptions = {
    address: undefined,
    city: undefined,
    region: 'full',
    postcode: undefined,
    country: undefined,
    singleLine: true,
  }
}
