import React, { useCallback, useEffect, useRef } from 'react'

const getWordBreakClass = (breakOn?: 'normal' | 'word' | 'all') => {
  switch (breakOn) {
    case 'normal':
      return 'break-normal'
    case 'word':
      return 'break-word'
    case 'all':
      return 'break-all'
    default:
      return ''
  }
}

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  as?: React.ElementType
  lines?: number
  breakOn?: 'normal' | 'word' | 'all'
  isDisabled?: boolean
  isFixed?: boolean
}

export const TruncatedText = ({
  as: Element = 'div',
  lines = 1,
  isFixed = false,
  isDisabled = false,
  breakOn,
  className,
  children,
  ...props
}: React.PropsWithChildren<IProps>): React.ReactElement => {
  const textRef = useRef<HTMLDivElement>(null)

  const textClassName = [
    lines >= 1 && !isDisabled ? `text-truncate-${lines}` : '',
    isFixed ? 'fixed' : '',
    getWordBreakClass(breakOn),
    className,
  ]
    .filter((c, i, arr) => Boolean(c) && arr.indexOf(c) === i)
    .join(' ')

  const handleTruncate = useCallback(() => {
    if (textRef.current) {
      const hasEllipsis =
        textRef.current.scrollWidth > textRef.current.clientWidth ||
        textRef.current.scrollHeight > textRef.current.clientHeight
      if (hasEllipsis) {
        textRef.current.title = textRef.current.textContent?.trim() || ''
        textRef.current.classList.add('truncated')
      } else {
        textRef.current.removeAttribute('title')
        textRef.current.classList.remove('truncated')
      }
    }
  }, [textRef])

  useEffect(() => {
    handleTruncate()
    window.addEventListener('resize', handleTruncate)
    return () => {
      window.removeEventListener('resize', handleTruncate)
    }
  }, [handleTruncate, textRef, children])

  return (
    <Element {...props} className={textClassName} ref={textRef}>
      {children}
    </Element>
  )
}
